import { useLocation } from '@gatsbyjs/reach-router';
import { navigate } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { ZodFormattedError } from 'zod';

import { RegisterPersonalSchema } from './schemas';
import * as Style from './style';

import { Input } from '@components/controls/Input';
import { Button } from '@components/controls/button';
import { CheckBox } from '@components/controls/checkbox';
import { StyledSelect } from '@components/controls/select';
import { Block } from '@components/layout/block';
import { Card } from '@components/layout/card';
import { Center } from '@components/layout/center';
import { HStack } from '@components/layout/hstack';
import { VStack } from '@components/layout/vstack';
import { Anchor } from '@components/typographies/anchor';
import { ErrorMessage } from '@components/typographies/error';
import { Heading } from '@components/typographies/heading';
import { Label } from '@components/typographies/label';
import { Paragraph } from '@components/typographies/paragraph';
import { useAuth } from '@hooks/useAuth';
import {
  handleCheckChange,
  handleInputChange,
  handleSelectChange,
  handleSubmit,
} from '@lib/handlers';
import { User } from '@pb/api/v1/user/common_pb';

type FieldValue = Pick<
  User.AsObject,
  'firstName' | 'lastName' | 'firstKana' | 'lastKana' | 'gender'
> & {
  year: string;
  month: string;
  day: string;
  hash: string;
};

const initForm: FieldValue = {
  hash: '',
  firstName: '',
  lastName: '',
  firstKana: '',
  lastKana: '',
  year: '',
  month: '',
  day: '',
  gender: 1,
};

const BasicInfo: FC = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<FieldValue>(initForm);
  const [errors, setErrors] = useState<Partial<ZodFormattedError<FieldValue>>>({});
  const location = useLocation();

  const hash = location.search.split('=')[1];

  useEffect(() => {
    setFormValue((prev) => ({ ...prev, hash: hash }));
  }, [hash]);

  const patch = (v: Partial<FieldValue>) => {
    setFormValue((prev) => ({ ...prev, ...v }));
  };

  const [_, { register }] = useAuth();

  const validateForm = (data: FieldValue) => {
    const result = RegisterPersonalSchema.safeParse(data);
    if (!result.success) {
      setErrors(result.error.format());
      return false;
    }
    setErrors({});
    return true;
  };

  const submitForm = (data: FieldValue) => {
    const date = new Date(Number(data.year), Number(data.month) - 1, Number(data.day));
    register({ ...data, birthday: date.toISOString() }).then(() =>
      navigate('/official-signup/complete')
    );
  };

  const onSubmit = () => {
    if (validateForm(formValue)) {
      submitForm(formValue);
    }
  };

  return (
    <Block width="100%" padding="12px">
      <Card
        bg="skin"
        width="100%"
        md={{ width: '628px', paddingInline: '104px' }}
        px="12px"
        mx="auto"
        py="48px"
      >
        <Heading mb="27px" align="center" mx="auto" fontSize="24px">
          新規会員登録
        </Heading>
        <VStack align="flex-start" as="form" spacing="15px" onSubmit={handleSubmit(onSubmit)}>
          <Label display="block">
            <Paragraph>氏名＊</Paragraph>
            <HStack spacing="10px">
              <Input
                width="100%"
                md={{ width: '200px' }}
                height="35px"
                onChange={handleInputChange((lastName) => patch({ lastName }))}
                placeholder="山田"
              />
              <Input
                width="100%"
                md={{ width: '200px' }}
                height="35px"
                onChange={handleInputChange((firstName) => patch({ firstName }))}
                placeholder="太郎"
              />
            </HStack>
          </Label>
          <Label>
            <Paragraph>フリガナ＊</Paragraph>
            <HStack spacing="10px">
              <Input
                width="100%"
                md={{ width: '200px' }}
                height="35px"
                onChange={handleInputChange((lastKana) => patch({ lastKana }))}
                placeholder="ヤマダ"
              />
              <Input
                width="100%"
                md={{ width: '200px' }}
                height="35px"
                placeholder="タロウ"
                onChange={handleInputChange((firstKana) => patch({ firstKana }))}
              />
            </HStack>
          </Label>
          <Label>
            <Paragraph>性別＊</Paragraph>
            <HStack spacing="12px">
              <Button
                onClick={() => {
                  patch({ gender: 1 });
                }}
                width="78px"
                color={formValue.gender === 1 ? 'orange' : 'gray'}
                radius="10px"
                as="label"
                bg="white"
                htmlFor="0"
              >
                男性
              </Button>
              <Button
                onClick={() => {
                  patch({ gender: 2 });
                }}
                width="78px"
                color={formValue.gender === 2 ? 'orange' : 'gray'}
                radius="10px"
                as="label"
                bg="white"
                htmlFor="1"
              >
                女性
              </Button>
              <Button
                onClick={() => {
                  patch({ gender: 3 });
                }}
                width="78px"
                color={formValue.gender === 3 ? 'orange' : 'gray'}
                radius="10px"
                as="label"
                bg="white"
                htmlFor="2"
              >
                その他
              </Button>
            </HStack>
          </Label>
          <Label>
            <Paragraph>生年月日＊</Paragraph>
            <HStack spacing="10px">
              <StyledSelect
                value={formValue.year}
                onChange={handleSelectChange((year) => patch({ year }))}
                onMouseDown={() => patch({ year: formValue.year || '1980' })}
                width="100px"
                height="35px"
              >
                <option value="">年</option>
                {Array.from({ length: 123 }, (_, i) => (
                  <option key={i} value={String(1900 + i)}>
                    {1900 + i}
                  </option>
                ))}
              </StyledSelect>
              <StyledSelect
                width="74px"
                height="35px"
                value={formValue.month}
                onChange={handleSelectChange((month) => patch({ month }))}
              >
                <option value="">月</option>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i} value={String(1 + i)}>
                    {1 + i}
                  </option>
                ))}
              </StyledSelect>
              <StyledSelect
                width="74px"
                height="35px"
                value={formValue.day}
                onChange={handleSelectChange((day) => patch({ day }))}
              >
                <option value="">日</option>
                {formValue.year &&
                  formValue.month &&
                  Array.from(
                    {
                      length: new Date(
                        Number(formValue.year),
                        Number(formValue.month),
                        0
                      ).getDate() /* これで月の最終日が取得できる */,
                    },
                    (_, i) => (
                      <option key={i} value={String(1 + i)}>
                        {1 + i}
                      </option>
                    )
                  )}
              </StyledSelect>
            </HStack>
          </Label>
          {Object.keys(errors).filter((key) => key !== '_errors').length > 0 && (
            <ErrorMessage>
              {
                errors[
                  Object.keys(errors).filter((key) => key !== '_errors')[0] as keyof FieldValue
                ]?._errors
              }
            </ErrorMessage>
          )}
          <Paragraph fontSize="14px">
            当サービスの
            <Anchor
              href={process.env.CUSTOMER_RULE_URL}
              target="_blank"
              rel="noreferrer noopener"
              fontSize="14px"
              color="black"
              bold
            >
              利用規約
            </Anchor>
            及び
            <Anchor
              href={process.env.LAW_URL}
              target="_blank"
              rel="noreferrer noopener"
              fontSize="14px"
              color="black"
              bold
            >
              プライバシーポリシー
            </Anchor>
            にご同意の上、登録してください。
          </Paragraph>
          <Center>
            <CheckBox
              id="policy"
              onChange={handleCheckChange(setIsChecked)}
              placeholder="上記に同意します"
            />
          </Center>
          <Style.ButtonWrapper>
            <Button
              width="210px"
              height="35px"
              bg="orange"
              color="white"
              type="submit"
              disabled={!isChecked || Object.values(formValue).includes('')}
            >
              新規会員登録
            </Button>
          </Style.ButtonWrapper>
        </VStack>
      </Card>
    </Block>
  );
};

export default BasicInfo;
