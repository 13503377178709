import { z } from 'zod';

export const RegisterPersonalSchema = z.object({
  hash: z.string().nonempty({ message: '不正なリクエストです。' }),
  firstName: z.string().nonempty({ message: '名前を入力してください。' }),
  lastName: z.string().nonempty({ message: '苗字を入力してください。' }),
  firstKana: z
    .string()
    .nonempty({ message: 'カタカナで名前を入力してください。' })
    .regex(/^[ァ-ヶー　 ]*$/, { message: '全角カタカナで入力してください' }),
  lastKana: z
    .string()
    .nonempty({ message: 'カタカナで苗字を入力してください。' })
    .regex(/^[ァ-ヶー　 ]*$/, { message: '全角カタカナで入力してください' }),
  year: z.string().nonempty({ message: '年を選択してください。' }),
  month: z.string().nonempty({ message: '月を選択してください。' }),
  day: z.string().nonempty({ message: '日を選択してください。' }),
  gender: z.number().int().min(1).max(3),
});
