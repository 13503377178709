import styled from 'styled-components';

export const SignupWrapper = styled.div`
  width: 630px;
  height: 500px;
  padding: 90px 55px;
  margin: 0 auto;
  border-radius: 25px;
  background: #fdf6e8;
`;

export const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 40px;
`;

export const Text1 = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  margin: 20px 0;
`;

export const Text2 = styled.div`
  border-bottom: 1px solid black;
  :hover {
    opacity: 0.6;
  }
  cursor: pointer;
`;

export const Text3 = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;

export const Text4 = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`;

export const Text5 = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

export const InputGroup = styled.div`
  width: 410px;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  div {
    input {
      border-radius: 0;
    }
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  align-items: center;
`;
export const FlexDiv2 = styled.div`
  width: 570px;
  display: flex;
  margin: 20px auto;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  width: 210px;
  margin: 30px auto;
  button {
    border-radius: 30px;
  }
`;

export const Bar = styled.div`
  border-bottom: 1px solid #bababa;
  margin: 30px 0;
`;

export const ErrorMessage = styled.div`
  margin: 30px 0;
  color: red;
  text-align: center;
`;

export const NameField = styled.div`
  width: 400px;
  margin: 20px auto;
`;

export const GenderField = styled.div`
  width: 400px;
  margin: 20px auto;
  .gender {
    display: flex;
  }
  .gender label {
    display: block;
    width: 150px;
    background: #ffffff;
    color: #000;
    padding: 10px;
    margin: 10px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    border-radius: 30px;
    cursor: pointer;
  }
  .gender input:checked + label {
    background: #f39800;
    color: #fff;
  }
  .gender input {
    display: none;
  }
`;

export const ButtonGroup = styled.div`
  width: 280px;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  button {
    border-radius: 10px;
  }
`;

export const BirthDayField = styled.div`
  width: 400px;
  margin: 20px auto;
`;

export const SelectGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;
