import styled from 'styled-components';

type Props = {
  spacing: string;
  px?: string;
  py?: string;
  width?: 'max-content' | '100%' | string;
  align?: 'center' | 'flex-start' | 'flex-end';
  justify?: 'center' | 'flex-start' | 'flex-end';
  maxWidth?: string;
  wrap?: boolean;
  margin?: string;
  rg?: string;
};

export const HStack = styled.div<Props>((_) => ({
  display: 'flex',
  flexDirection: 'row',
  width: _.width,
  height: 'max-content',
  paddingLeft: _.px ?? '0',
  paddingRight: _.px ?? '0',
  paddingTop: _.py ?? '0',
  paddingBottom: _.py ?? '0',
  alignItems: _.align ?? 'center',
  justifyContent: _.justify,
  maxWidth: _.maxWidth,
  margin: _.margin,
  flexWrap: _.wrap ? 'wrap' : 'nowrap',
  rowGap: _.rg,
  '> * + *': {
    marginLeft: _.wrap ? '' : _.spacing,
  },
  '> *': {
    marginRight: _.wrap ? _.spacing : '',
  },
}));
