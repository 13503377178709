import styled, { CSSObject } from 'styled-components';

type Props = {
  width?: string;
  height?: string;
  lh?: string;
  borderRadius?: string;
  hasBorder?: boolean;
  md?: CSSObject;
  bg?: string;
};

export const Input = styled.input<Props>((_) => ({
  width: _.width ?? '100%',
  height: _.height ?? '35px',
  padding: '0 10px',
  fontSize: '15px',
  background: `var(--color-bg-${_.bg ?? 'white'})`,
  border: _.hasBorder ? '1px solid var(--color-input-border-gray)' : 'none',
  borderRadius: _.borderRadius ?? '0',
  '@media (min-width: 630px)': _.md,
}));
